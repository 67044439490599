<template>
  <div>
    <div class="shopify-section">
      <div class="section-header flex-bet-cen">
        <div class="section-select-wrap flex-cen font-light">
          <div
            class="section-select-item"
            v-for="item in categoryMenu"
            :key="item.id"
            :class="item.id === selectUnder ? 'active' : ''"
            @click="changeSelectIndex(item.id)"
          >
            {{
              $trans(`category-${item.id}.categoryName`, item.categoryName) ||
              item.categoryName
            }}
          </div>
        </div>
        <div
          v-if="!searchForArtist"
          class="section-select-item font-light"
          style="margin-right: 0"
          @click="openFiltrateHandle"
        >
          {{ $trans('筛选', '筛选') }}
        </div>
      </div>
      <div v-if="data.length < 1" class="empty-box" @click="changeMenuHandle">
        <el-empty
          image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
        ></el-empty>
      </div>
      <div
        v-if="data.length >= 1 && !searchForArtist"
        class="featured-collection__product"
        v-infinite-scroll="load"
        infinite-scroll-delay="100"
        infinite-scroll-distance="600"
      >
        <ShopItem
          :class="[
            'featured-collection__product-item',
            index === 0 ? 'featured-collection__product_first' : '',
          ]"
          v-for="(item, index) in data"
          :key="index"
          :data="item"
          :first="index === 0 ? true : false"
        />
      </div>

      <div
        v-if="searchForArtist"
        class="featured-collection__product"
        v-infinite-scroll="load"
        infinite-scroll-delay="100"
        infinite-scroll-distance="600"
      >
        <ShopItem
          class="featured-collection__product-item"
          v-for="(item, index) in data"
          :key="index"
          :data="item"
        />
      </div>

      <el-drawer :visible.sync="filtrateShow" direction="rtl">
        <div>
          <div class="select-box-title font-medium">
            {{ $trans('筛选', '筛选') }}
          </div>
          <div class="flex-column-start">
            <label
              v-for="(item, index) in filtrateList"
              :key="index"
              :for="item.ident"
              class="flex-bet select-box-item"
            >
              <span>{{
                $trans(`category-${item.id}.categoryName`, item.categoryName)
              }}</span>
              <input
                :id="item.id"
                :value="item.id"
                type="radio"
                name="selectTwo"
                v-model="filtrateSelectIndex"
              />
            </label>
          </div>
          <div class="fixed-wrap">
            <div
              class="btn-fill"
              @click="resetHandle"
              style="background: rgb(144, 144, 144); margin-bottom: 30px"
            >
              {{ $trans('重置', '重置') }}
            </div>
            <div
              class="btn-fill"
              style="background: rgb(87, 87, 87)"
              @click="confirmHandle"
            >
              {{ $trans('保存', '保存') }}
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
    <Footer />
  </div>
</template>
<script>
import { loadLocaleMessages } from '@/i18n/i18n'
import ShopItem from '@/components/shop/ShopItem.vue'
import Footer from '@/components/layout/Footer.vue'
import {
  getAllGoodsList,
  getCategoryMenu,
  getCategoryGoods,
} from '@/util/js/api.js'
export default {
  name: '',
  components: {
    ShopItem,
    Footer,
  },
  data() {
    return {
      categoryMenu: [], // 顶部菜单列表
      categoryIdMenu: [], // 顶部菜单id列表
      selectUnder: 0, // 顶部下划线
      isGetMenu: false, // 是否在请求顶部菜单列表
      selectIndex: 0, // 当前选择项id
      total: 1, // 商品数据总条数
      isGetData: false, // 是否在获取数据
      data: [], // 商品数据
      page: 1, // 当前页
      pageSize: 10, // 每页条数
      filtrateShow: false, // 筛选框显示隐藏
      filtrateList: [], // 筛选框内容
      filtrateSelectIndex: 0, // 筛选框选择内容
      searchForArtist: false,
      artistId: 0,
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave:', to, from, next)
    const homepage = document.querySelector('.main__layout').scrollTop
    const postion = {
      gallery: homepage,
    }
    localStorage.setItem('position', JSON.stringify(postion))
    next()
  },
  methods: {
    tunrnTo() {
      let postion = localStorage.getItem('position')
      postion = JSON.parse(postion)
      if (postion && postion['gallery']) {
        document
          .querySelector('.main__layout')
          .scrollTo({ top: postion['gallery'], behavior: 'smooth' })
        localStorage.setItem('position', JSON.stringify({}))
      }
    },
    // 获取菜单列表
    getCategoryMenu(params) {
      if (this.isGetMenu) {
        this.$message.warning(
          this.$trans('请不要频繁发送请求', '请不要频繁发送请求')
        )
        return false
      } else {
        this.isGetMenu = true
        if (this.artistId != 0) {
          params['artistId'] = this.artistId
        }

        getCategoryMenu(params)
          .then((res) => {
            this.isGetMenu = false
            this.filtrateList = JSON.parse(JSON.stringify(res.data))
            res.data.forEach((item) => {
              if (item.transferList) {
                loadLocaleMessages(item.transferList, `category-${item.id}`)
              }
            })
            if (this.selectIndex) {
              this.filtrateShow = true
              return
            }
            this.categoryMenu = JSON.parse(JSON.stringify(res.data))
            if (this.categoryMenu) {
              this.categoryMenu.forEach((item) => {
                this.categoryIdMenu.push(item.id)
              })
            }
          })
          .catch((err) => {
            this.isGetMenu = false
            this.$message.error(err.message)
          })
      }
    },
    // 触底加载
    load() {
      if (this.isBottom) {
        return
      }
      if (this.selectIndex) {
        this.getCategoryGoods(
          {
            page: ++this.page,
            limit: this.pageSize,
            categoryId: this.filtrateSelectIndex
              ? this.filtrateSelectIndex
              : this.selectIndex,
          },
          false
        )
        return
      }
      this.getAllGoodsList(++this.page, this.pageSize)
    },
    // 获取全部商品列表
    getAllGoodsList(page, pageSize) {
      if (this.isGetData) {
        this.$message.warning(
          this.$trans('请不要频繁发送请求', '请不要频繁发送请求')
        )
        return false
      } else {
        this.isGetDrawing = true
        const reqParams = {
          page: page + '',
          limit: pageSize + '',
        }
        if (this.artistId != 0) {
          reqParams['artistId'] = this.artistId
        }
        getAllGoodsList(reqParams)
          .then((res) => {
            this.data = [
              ...this.data,
              ...JSON.parse(JSON.stringify(res.data.records)),
            ]
            this.total = parseInt(res.data.total)
            this.isGetData = false
            res.data.records.forEach((item) => {
              if (item.transferList) {
                loadLocaleMessages(item.transferList, `goods-${item.id}`)
              }
              if (item.artistTransferList) {
                loadLocaleMessages(
                  item.artistTransferList,
                  `artist-${item.artistId}`
                )
              }
            })
          })
          .catch((err) => {
            this.isGetData = false
            this.page--
            this.$message.error(err.message)
          })
      }
    },
    // 获取分类商品
    getCategoryGoods(params, change) {
      if (this.artistId != 0) {
        params['artistId'] = this.artistId
      }
      getCategoryGoods(params).then((res) => {
        if (change) {
          this.data = JSON.parse(JSON.stringify(res.data.records))
          this.total = parseInt(res.data.total)
          res.data.records.forEach((item) => {
            if (item.transferList) {
              loadLocaleMessages(item.transferList, `category-${item.id}`)
            }
            if (item.artistTransferList) {
              loadLocaleMessages(
                item.artistTransferList,
                `artist-${item.artistId}`
              )
            }
          })
        } else {
          this.data = [
            ...this.data,
            ...JSON.parse(JSON.stringify(res.data.records)),
          ]
        }
      })
    },
    // 头部点击筛选
    changeSelectIndex(id) {
      this.selectIndex = id
      this.selectUnder = id
      this.filtrateSelectIndex = id
      this.page = 1
      this.total = 1
      this.getCategoryGoods(
        { page: this.page, limit: this.pageSize, categoryId: this.selectIndex },
        true
      )
    },
    // 打开筛选
    openFiltrateHandle() {
      this.filtrateSelectIndex = 0
      if (
        this.selectIndex &&
        this.categoryIdMenu.indexOf(this.selectIndex) > -1
      ) {
        this.getCategoryMenu({ id: this.selectIndex })
      } else {
        this.filtrateShow = true
      }
    },
    // 重置选项
    resetHandle() {
      this.filtrateSelectIndex = 0
    },
    // 筛选 确定选项
    confirmHandle() {
      if (this.filtrateSelectIndex) {
        if (this.categoryIdMenu.indexOf(this.filtrateSelectIndex) > -1) {
          this.selectUnder = this.filtrateSelectIndex
        }
        this.page = 1
        this.total = 1
        this.selectIndex = this.filtrateSelectIndex
        this.getCategoryGoods(
          {
            page: this.page,
            limit: this.pageSize,
            categoryId: this.selectIndex,
          },
          true
        )
      }
      this.filtrateShow = false
    },
    // 没有数据时点击
    changeMenuHandle() {
      this.page = 0
      if (this.selectIndex) {
        this.getCategoryGoods(
          {
            page: ++this.page,
            limit: this.pageSize,
            categoryId: this.filtrateSelectIndex
              ? this.filtrateSelectIndex
              : this.selectIndex,
          },
          true
        )
        return
      }
      this.getAllGoodsList(++this.page, this.pageSize)
    },
  },
  mounted() {
    this.searchForArtist = this.$route.query.id ? true : false
    this.artistId = this.$route.query.id
    this.getCategoryMenu({ id: this.selectIndex })
    this.getAllGoodsList(this.page, this.pageSize)
    if (document.querySelector('.main__layout')) {
      setTimeout(() => {
        this.tunrnTo()
      }, 300)
    }
  },
  computed: {
    isBottom() {
      return this.data.length >= this.total
    },
  },
}
</script>
<style scoped>
.shopify-section {
  margin: 80px 60px;
  max-width: 1700px;
}
.section-header {
  margin-bottom: 30px;
}
.featured-collection__product {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 30px;
}
.featured-collection__product-item {
  position: relative;
}
.featured-collection__product_first {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}
.section-select-item {
  margin-right: 30px;
  font-size: 16px;
  line-height: 1.4;
  position: relative;
  cursor: pointer;
}
.section-select-item.active {
  color: #909090;
}
.section-select-item.active::after {
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 2px;
  background: #909090;
  content: '';
}
.select-box-item {
  width: 100%;
  font-size: 16px;
  margin-bottom: 20px;
  cursor: pointer;
}
.select-box-title {
  font-size: 20px;
  margin-bottom: 40px;
}
.fixed-wrap {
  position: absolute;
  bottom: 60px;
  width: 100%;
}
.btn-fill {
  cursor: pointer;
}
@media screen and (min-width: 1200px) {
  .featured-collection__product {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1600px) {
  .featured-collection__product {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
<style scoped>
input[type='checkbox']:checked::before {
  background: none;
  border: 1px solid #000000;
  border-left: none;
  border-top: none;
  border-radius: unset;
  width: 5px;
  height: 10px;
  transform: rotate(45deg);
}
input[type='checkbox']::before {
  border: none;
}
</style>
<style>
.el-drawer__body {
  position: relative;
}
</style>
