<template>
  <a
    :href="data.link ? data.link : '/shop/detail/' + data.id"
    class="component__shop-item flex-column-start"
  >
    <div
      class="iframe-wrap flex-cen-cen"
      :class="first ? 'first-iframe-wrap' : ''"
    >
      <div
        class="border-hover"
        :class="[data.styleType == 0 ? '' : 'border-iframe-outer']"
      >
        <div :class="[data.styleType == 0 ? '' : 'border-iframe-inner']">
          <img src="" v-lazy="data.image" alt="" />
        </div>
      </div>
      <!-- <div
        class="already-sold-out font-bold flex-cen-cen"
        v-if="data.goodsStatus < 1"
      >
        已售罄
      </div> -->
    </div>
    <span class="object__message">
      <div class="object__name font-medium">
        {{ $trans(`goods-${data.id}.title`, data.title) }}
      </div>
      <div class="object__author">
        {{
          '「' + $trans(`artist-${data.artistId}.name`, data.artistName) + '」'
        }}
      </div>
    </span>
    <div class="object__price">
      <div style="font-style: normal" v-if="data.price || data.price === 0">
        ￥
      </div>
      <div class="object__int" v-if="data.price || data.price === 0">
        {{ data.price | product_int }}
      </div>
      <div class="object__float" v-if="data.price || data.price === 0">
        {{ data.price | product_float }}
      </div>
    </div>
    <div class="product-item__badge" v-if="data.goodsStatus < 1">
      {{ $trans('已售罄', '已售罄') }}
    </div>
    <div class="product-item__badge" v-else-if="data.flag">
      {{ data.flag && data.flag.toUpperCase() }}
    </div>
  </a>
</template>
<script>
export default {
  name: '',
  props: {
    data: {
      typeof: Object,
    },
    first: {
      typeof: Boolean,
    },
  },
}
</script>
<style scoped>
.component__shop-item {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.border-hover {
  transition: all 0.5s;
}
.component__shop-item:hover .border-hover {
  transform: scale(1.2);
  border-color: rgb(209, 209, 209);
  box-shadow: #dddddd;
  transition: all 0.5s;
}
/* .component__shop-item:hover .border-iframe-outer {
  transform: scale(1.2);
  border-color: rgb(209, 209, 209);
  box-shadow: #dddddd;
} */

.object__message {
  margin-top: 15px;
  margin-bottom: 6px;
  line-height: 1.6;
}
.object__author {
  color: #595959;
  font-size: 12px;
  transform: translateX(-6px);
}
.object__price {
  font-size: 16px;
}
.product-item__badge {
  padding: 0 10px;
  position: absolute;
  top: 0;
  right: 0;
  /* width: 60px; */
  min-width: 60px;
  height: 40px;
  max-height: 40px;
  background: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
}
.iframe-wrap {
  width: 100%;
  height: 300px;
  background: rgb(226, 226, 226);
  position: relative;
}
.already-sold-out {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgb(0, 0, 0, 0.6);
  opacity: 0.7;
  color: #ffffff;
  font-size: 30px;
}
.first-iframe-wrap .already-sold-out {
  font-size: 60px;
}
.border-iframe-outer {
  display: inline-block;
  border: 6px solid #111111;
  transition: all 0.5s;
  box-shadow: 3px 3px 3px #999999;
}
.border-iframe-inner {
  border: 6px solid #ffffff;
}
img {
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
  display: block;
}
.first-iframe-wrap {
  width: 100%;
  height: 500px;
  background: rgb(226, 226, 226);
}
.first-iframe-wrap .border-iframe-outer {
  display: inline-block;
  border: 12px solid #111111;
}
.first-iframe-wrap .border-iframe-inner {
  border: 12px solid #ffffff;
}
.first-iframe-wrap img {
  max-width: 300px;
  max-height: 300px;
  width: auto;
  height: auto;
  display: block;
}
@media screen and (min-width: 1200px) {
  .first-iframe-wrap {
    height: 708px;
  }
  .first-iframe-wrap img {
    max-width: 400px;
    max-height: 400px;
    width: auto;
    height: auto;
    display: block;
  }
}
</style>
