<template>
  <div class="footer-component">
    <div class="feature-wrap flex-bet">
      <div
        class="feature-item flex-column-start"
        v-for="item in footer"
        :key="item.id"
      >
        <div class="feature-img">
          <img :src="item.src" alt="" />
        </div>
        <div class="feature-title font-bold">
          {{ $trans(item.title, item.title) }}
        </div>
        <div class="feature-content">
          {{ $trans(item.content, item.content) }}
        </div>
      </div>
    </div>
    <div class="linkage-wrap">
      <div class="linkage-item" v-for="item in about" :key="item.id">
        <div class="linkage-title font-bold">
          {{ $trans(item.title, item.title) }}
        </div>
        <div v-if="item.content">
          <p
            class="p font-light"
            v-for="(i, index) in item.content"
            :key="index"
          >
            {{ $trans(i, i) }}
          </p>
        </div>
        <span v-else-if="item.links" class="flex-column">
          <a
            v-for="i in item.links"
            :href="i.path"
            :key="i.id"
            class="linkage-link"
          >
            <span>
              {{ $trans(i.label, i.label) }}
            </span>
          </a>
        </span>
      </div>
      <div class="footer-about-account">
        <div>{{ $trans('订阅我们的公众号', '订阅我们的公众号') }}</div>
        <div>
          {{
            $trans(
              '更多精彩上新第一时间通知到你',
              '更多精彩上新第一时间通知到你'
            )
          }}
        </div>
        <img src="@/assets/img/icon/qr_code.png" />
      </div>
    </div>
    <div class="footer-copyright-wrap flex-cen-cen">
      <span>
        版权所有 @上殊房
        <a target="_blank" href="https://beian.miit.gov.cn/"
          >浙ICP备2022002244号-3</a
        >
      </span>
    </div>
  </div>
</template>
<script>
import { footer, footerAbout } from '@/util/resource/static.jsx'
export default {
  name: '',
  computed: {
    footer() {
      return footer()
    },
    about() {
      return footerAbout()
    },
  },
}
</script>
<style scoped>
.feature-wrap {
  background: rgb(245, 245, 245);
  padding: 60px 30px 80px;
}
.feature-title {
  line-height: 2.5;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
}
.feature-content {
  font-size: 14px;
}
.feature-img {
  width: 60px;
  height: 60px;
  margin-bottom: 30px;
}
.linkage-wrap {
  background: rgb(17, 17, 17);
  color: #ffffff;
  padding: 60px 60px 80px;
  display: flex;
  justify-content: space-between;
}
.linkage-wrap > div:first-child {
  width: 40%;
  margin-right: 5%;
}

.linkage-item-left {
  width: 40%;
}
.linkage-item {
  width: 25%;
}
.linkage-title {
  font-size: 16px;
  line-height: 2.5;
  font-weight: bold;
  margin-bottom: 20px;
}
.p {
  /* width: 280px; */
  line-height: 30px;
  font-size: 12px;
  letter-spacing: 2px;
}
.linkage-link {
  font-size: 12px;
  /* width: 280px; */
  line-height: 30px;
}
.footer-about-account {
  line-height: 30px;
  font-size: 14px;
}
.footer-about-account img {
  width: 100px;
  height: auto;
  margin-top: 30px;
}
.footer-copyright-wrap {
  flex-direction: column;
  font-size: 12px;
  /* line-height: 40px; */
  padding: 20px 0;
}

.flex-column {
  align-items: flex-start;
}

.feature-item {
  flex-basis: 31%;
}
</style>
